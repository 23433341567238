import React, { useMemo } from 'react'
import axios from 'axios'

import { useAuth } from 'auth'

import classnames from 'classnames'
import { format } from 'date-fns'
import {useHistory, useParams} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Button,
  Chip,
} from '@material-ui/core'

import config from 'config'

import getGinState from '../../utils/getGinState'
import getCartState from '../../utils/getCartState'
import useDownload from '../../hooks/useDownload'

import {
  PRODUCTION_STATUS_NO_LABEL,
  PRODUCTION_STATUS_SHIPPED,
  PRODUCTION_STATUS_TO_DO,
  PRODUCTION_STATUS_DONE,
  PRODUCTION_STATUS_TO_VERIFY,
  ProductionStatusLabel,
  CART_STATE_PAID,
} from '../../constants'

import getGinId from '../../utils/getGinId'

import {useBuilderConfig} from "../../hooks/useBuilderConfig"

import OrderWidgetActions from './OrderWidgetActions'

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 900,
    display: 'flex',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    height: 210,
  },
  details: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  content: {
    width: '100%',
    marginLeft: theme.spacing(2),
  },
  cover: {
    width: 171,
    minWidth: 171,
    height: 171,
  },
  col1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 155,
    minWidth: 300,
  },
  packageCard: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
    backgroundColor: '#f6f6f6',
    paddingLeft: 10,
    marginRight: 10,
    marginBottom: 10,
  },
  productionCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 70,
    backgroundColor: '#cdecc0',
    marginRight: 10,
    marginBottom: 10,
  },
  productionInnerCard: {
    display: 'flex',
    marginTop: '7px',
    alignItems: 'center',
    backgroundColor: '#cdecc0',
  },
  bottleAvatar: {
    margin: 5,
  },
  gridRoot: {
    width: '100%',
    flexGrow: 1,
  },
  productionState: {
    marginTop: 10,
    color: 'white',
    fontWeight: 600,
    width: '100%',
    '&.toVerify': {
      backgroundColor: 'lightBlue',
    },
    '&.missingLabel': {
      backgroundColor: 'red',
    },
    '&.toDo': {
      backgroundColor: 'orange',
    },
    '&.done': {
      backgroundColor: 'saddlebrown',
    },
    '&.sent': {
      backgroundColor: 'green',
    },
  },
  upsell: {
    backgroundColor: '#4d8e00',
    color: '#ffffff',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '8px',
    width: '240px',
    padding: '4px',
    textAlign: 'center',
  },
  hashChanged: {
    backgroundColor: '#DC143C',
    color: '#ffffff',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '8px',
    width: '240px',
    padding: '4px',
    textAlign: 'center',
  },
}))

const Customization = ({ title, type }) => {
  const classes = useStyles()

  return (
    <Card className={classes.packageCard}>
      <Typography variant="h4" color="textSecondary">
        {title}
      </Typography>
      <Typography variant="h4" style={{ marginLeft: 20 }} noWrap>
        {type}
      </Typography>
    </Card>
  )
}

const ProductionInfo = ({ production }) => {
  const classes = useStyles()

  if (!production?.day) {
    return
  }

  return (
    <Card className={classes.productionCard}>
      <div className={classes.productionInnerCard}>
        <Typography variant="h3" style={{ marginRight: 10 }}>
          {production.batch}
        </Typography>
        <Typography variant="h3" color="textSecondary">
          {format(new Date(production.day), 'yyyy-MM-dd')}
        </Typography>
      </div>
      <div className={classes.productionInnerCard}>
        <Typography variant="h3" color="textSecondary">
          {production.numbers.length <= 6
            ? production.numbers.join('-')
            : `${production.numbers[0]}...${
                production.numbers[production.numbers.length - 1]
              }`}
        </Typography>
      </div>
    </Card>
  )
}

const ProductionStatusClasses = {
  [PRODUCTION_STATUS_TO_VERIFY]: 'toVerify',
  [PRODUCTION_STATUS_NO_LABEL]: 'missingLabel',
  [PRODUCTION_STATUS_TO_DO]: 'toDo',
  [PRODUCTION_STATUS_DONE]: 'done',
  [PRODUCTION_STATUS_SHIPPED]: 'sent',
}

const ProductionStatus = ({ order }) => {
  const classes = useStyles()
  const ginState = getGinState(order)
  return (
    <Chip
      className={classnames(classes.productionState, [
        ProductionStatusClasses[ginState],
      ])}
      label={ProductionStatusLabel[ginState]}
    />
  )
}

const OrderWidget = ({ order, productionStatusFilters = [], setOrder, isB2B }) => {
  const classes = useStyles()
  const history = useHistory()
  const params = useParams()
  const {getCustomizationLabel} = useBuilderConfig()
  const area = params.area
  const { download } = useDownload()
  const { getAccessToken } = useAuth()

  const handleDownloadCertificate = async () => {
    const accessToken = await getAccessToken()

    const result = await axios.get(
      `${config.labelsApiUrl}download-certificate-pdf?ginId=${order.ginId}`,
      {
        headers: {
          Authorization: `bearer ${accessToken}`,
        },
      }
    )

    download(config.staticsPublicBaseUrl + result.data.key)
  }
  const handleDownloadLabel = async () => {
    const accessToken = await getAccessToken()

    const result = await axios.get(
      `${config.labelsApiUrl}download-label-image?ginId=${order.ginId}`,
      {
        headers: {
          Authorization: `bearer ${accessToken}`,
        },
      }
    )

    download(config.staticsPublicBaseUrl + result.data.key)
  }

  const handleDownloadLabelPDF = async () => {
    const accessToken = await getAccessToken()

    const result = await axios.get(
      `${config.labelsApiUrl}download-label-pdf?ginId=${order.ginId}`,
      {
        headers: {
          Authorization: `bearer ${accessToken}`,
        },
      }
    )

    download(config.staticsPublicBaseUrl + result.data.key)
  }

  const productId = useMemo(() => {
    return getGinId(order)
  }, [order])

  const cartState = getCartState(order)

  return (
    <Card className={classes.card}>
      <CardMedia className={classes.cover} image={order.labelImage} title="" />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Grid container className={classes.gridRoot} spacing={2}>
            <Grid item xs={4}>
              <div className={classes.col1}>
                <div>
                  <Typography variant="body1" gutterBottom>
                    Ordine n° {productId}{' '}
                    {order.relatedProducts?.length && (
                      <span style={{ color: 'red' }}>Carrello multiplo</span>
                    )}
                  </Typography>
                  {order.paymentDate && (
                    <Typography gutterBottom>
                      Del {format(new Date(order.paymentDate), 'dd MMM, HH:mm')}
                    </Typography>
                  )}
                  {productionStatusFilters.includes(-10) && (
                    <Typography gutterBottom>
                      Del{' '}
                      {format(new Date(order.checkedOutDate), 'dd MMM, HH:mm')}
                    </Typography>
                  )}
                  <Typography variant="h3" color="textSecondary" gutterBottom>
                    {order.ginName}
                  </Typography>

                  <Typography variant="h4" gutterBottom>
                    Ordine di: <br/>
                    <b>{order.shippingAddress.first_name} {order.shippingAddress.last_name} {order.shippingAddress.company}</b><br/>
                    <b>{order.shippingAddress.city} - {order.shippingAddress.state} ({order.shippingAddress.country})</b>
                  </Typography>

                </div>

                {order.upsellProducts?.length || order.hasFakeUpselling ? (
                  <div className={classes.upsell}>Ordine contiene UPSELL</div>
                ) : (
                  ''
                )}
                {order.hashChanged ? (
                  <div className={classes.hashChanged}>
                    Ordine modificato dopo pagamento
                  </div>
                ) : (
                  ''
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Customization title='Bottiglia' type={`${order.quantity} ${getCustomizationLabel('bottle', order.customizations?.bottle?.id)}`}/>
                <Customization title='Package' type={order.params.packaging}/>
                <Customization title='Capsula' type={getCustomizationLabel('capsule', order.customizations?.capsule?.id)}/>
                <ProductionInfo production={order.production} bottles={order.quantity}/>
              </div>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex">
                <Box pb={'2px'} pr={'2px'} width={130}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => history.push(`/${area}/orders/${order.ginId}`)}
                  >
                    {' '}
                    Visualizza
                  </Button>
                  <Button
                    fullWidth
                    variant="outlined"
                    className={classes.button}
                    onClick={() => handleDownloadCertificate()}
                  >
                    Certificato
                  </Button>
                </Box>
                <Box width={130}>
                  <Button
                    fullWidth
                    variant="outlined"
                    className={classes.button}
                    onClick={() => handleDownloadLabel()}
                  >
                    Etichetta
                  </Button>
                  <Button
                    fullWidth
                    variant="outlined"
                    className={classes.button}
                    onClick={() => handleDownloadLabelPDF()}
                  >
                    PDF
                  </Button>
                  {cartState === CART_STATE_PAID && (
                    <ProductionStatus order={order} />
                  )}
                </Box>
              </Box>
              <OrderWidgetActions order={order} setOrder={setOrder} isB2B={isB2B} />
            </Grid>
          </Grid>
        </CardContent>
      </div>
    </Card>
  )
}

export default OrderWidget
