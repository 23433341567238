import React, { useMemo } from 'react'
import { format } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import {
  Avatar,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from '@material-ui/core'

import config from 'config'

import {useBuilderConfig} from "../../hooks/useBuilderConfig"

import OrderWidgetP1 from './OrderWidgetP1'

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  botanyContent: {
    width: '100%',
  },
  cover: {
    margin: 10,
    width: 120,
    minWidth: 120,
    height: 120,
  },
  capsuleInfo: {
    color: '#000',
    marginTop: '30px',
    textAlign: 'center',
  },
  capsuleImage: {
    backgroundColor: '#eee',
    margin: 10,
    width: 120,
    minWidth: 120,
    height: 120,
  },
  botanies: {
    display: 'flex',
    minWidth: 300,
    paddingRight: 10,
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  botanyCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 130,
    height: 130,
    marginRight: 10,
    marginBottom: 10,
  },
  botanyAvatar: {
    width: 50,
    height: 50,
    margin: 5,
    marginBottom: 15,
  }
}))

const Botany = ({ botany }) => {
  const classes = useStyles()

  return (
    <Card className={classes.botanyCard}>
      <Avatar
        alt={botany.name}
        src={`${
          config.assetsPublicBaseUrl
        }images/builder2/icons/botanics/${botany.image.toLowerCase()}.png`}
        className={classes.botanyAvatar}
      />
      <Typography variant="h4" gutterBottom>
        {botany.name.substr(0, 15)}
      </Typography>
      <Typography variant="h2" color="textSecondary">
        {botany.intensity}
      </Typography>
    </Card>
  )
}

const Order = ({ order, refreshPage }) => {
  const classes = useStyles()
  const {getCustomizationLabel} = useBuilderConfig()

  return (
    <>
      <OrderWidgetP1 order={order} />
      <Card className={classes.card} elevation={0}>
        <div className={classes.top}>
          <CardMedia
            className={classes.cover}
            image={order.labelImage}
            title=""
          />
          <div className={classes.capsuleInfo}>
            <Typography variant="h4" gutterBottom>
              Tappo
            </Typography>
            <Typography variant="h2" color="secondary">
              {getCustomizationLabel('cork', order.customizations.cork.id)}
            </Typography>
          </div>
          <CardMedia
            className={classes.capsuleImage}
            image={`${config.assetsPublicBaseUrl}images/builder2/picker/cork_${order.customizations.cork.id}.png`}
            title=""
          />
        </div>
        {order.notes && (
          <CardContent>
            <Typography variant="h3" color="textSecondary" gutterBottom>
              Note
            </Typography>

            {order.notes?.split('\n').map((text, index) => (
              <Typography key={index}>{text}</Typography>
            ))}
          </CardContent>
        )}
        <CardContent className={classes.botanyContent}>
          <Box className={classes.botanies}>
            {order.recipe.botanics
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((botany) => (
                <Botany key={botany.id} botany={botany} />
              ))}
          </Box>
        </CardContent>
      </Card>
    </>
  )
}

export default Order
